import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Privacy,
} from '@components'

const meta = {
  title: 'Zaštita privatnosti | Protupožarna zaštita - FSB d.o.o.',
  description: 'FSB d.o.o. obvezuje se na zaštitu vaših osobnih podatka i zadržava ih samo onoliko dugo koliko su potrebni.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/zastita-privatnosti/', name: 'Zaštita privatnosti'},
      ]}
    />
    <Hero
      title=""
      subtitle="Zaštita privatnosti"
      description=""
    />
    <Privacy />
  </Page>
)

export default Homepage